var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "typography-settings-modal",
        centered: "",
        "no-close-on-backdrop": "",
        "footer-class": "flex-nowrap"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _vm.tag === "p"
                ? _c("h3", [_vm._v("EDIT PARAGRAPH P")])
                : _c("h3", [_vm._v("EDIT HEADLINE " + _vm._s(_vm.tag))])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3 border-right-light-grey",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeTypographySettingsModal(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { variant: "primary", block: "" },
                  on: { click: _vm.closeTypographySettingsModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "px-4 py-2" }, [
        _c(
          "div",
          { staticClass: "preview-box p-4 mb-4", style: _vm.styleObject },
          [_vm._v(" The quick brown fox jumps over the lazy dog ")]
        ),
        _c(
          "div",
          [
            _c(
              "b-row",
              { staticClass: "mb-4" },
              [
                _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                  _c("label", { staticClass: "mb-0 mt-2" }, [
                    _vm._v(" Font Family ")
                  ])
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "6" } },
                  [
                    _c("b-form-select", {
                      attrs: { options: _vm.fontList },
                      on: { change: _vm.fontFamilyHasChanged },
                      model: {
                        value: _vm.fontFamily,
                        callback: function($$v) {
                          _vm.fontFamily = $$v
                        },
                        expression: "fontFamily"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-tabs",
              { attrs: { "content-class": "mt-3" } },
              [
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Desktop", active: "" },
                    on: {
                      click: function($event) {
                        return _vm.preview("screen")
                      }
                    }
                  },
                  [
                    _vm.localTypographyStyles
                      ? _c("modal-content-font-settings", {
                          attrs: {
                            "device-type": "screen",
                            styles: _vm.localTypographyStyles.screen
                          },
                          on: {
                            "data-has-changed": function($event) {
                              return _vm.dataHasChanged($event, "screen")
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Tablet", lazy: "" },
                    on: {
                      click: function($event) {
                        return _vm.preview("tablet")
                      }
                    }
                  },
                  [
                    _vm.localTypographyStyles
                      ? _c("modal-content-font-settings", {
                          attrs: {
                            "device-type": "tablet",
                            styles: _vm.localTypographyStyles.tablet
                          },
                          on: {
                            "data-has-changed": function($event) {
                              return _vm.dataHasChanged($event, "tablet")
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Smartphone", lazy: "" },
                    on: {
                      click: function($event) {
                        return _vm.preview("mobile")
                      }
                    }
                  },
                  [
                    _vm.localTypographyStyles
                      ? _c("modal-content-font-settings", {
                          attrs: {
                            "device-type": "mobile",
                            styles: _vm.localTypographyStyles.mobile
                          },
                          on: {
                            "data-has-changed": function($event) {
                              return _vm.dataHasChanged($event, "mobile")
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }